import { IJsonapiModel } from 'datx-jsonapi';
import { useSWRConfig } from 'swr';

import { IMutationOptions, useMutation, useResource } from '@/lib/datx-jsonapi-react';
import { detroyUserSession } from '@/mutations/sessions';
import { queryUserSession } from '@/queries/sessions';
import { SESSION_ME } from '@/utils/constants';

import { useStores } from './useStores';

export function useLogoutMutation<TInput, TModel extends IJsonapiModel = IJsonapiModel>(
  options?: IMutationOptions<TInput, TModel>
) {
  const { store } = useStores();
  const { mutate, cache } = useSWRConfig();
  const { mutate: mutateSession } = useResource(queryUserSession(SESSION_ME));

  return useMutation(() => detroyUserSession(store), {
    ...options,
    onSuccess: (...args) => {
      mutateSession(null, false);

      // @ts-ignore
      Array.from(cache.keys()).forEach((key) => {
        mutate(key, null, { revalidate: true });
      });

      options.onSuccess?.(...args);
    },
  });
}
